<template>
  <div :class="mode_class">
    <audio v-if="tv_mode" ref="audioPlayer" :src="currentAudioUrl"></audio>
    <div v-if="tv_mode && welcomeDlgVisible" class="welcomeDlg">
      <div class="content">为了能正常播放声音，您需要点击下方按钮！</div>
      <div class="btn" @click="welcomeDlgVisible = false">确定</div>
    </div>
    <qr-code-component :text="consoleUrl" :message="message" v-if="consoleUrl && tv_mode"></qr-code-component>
    <div v-if="tv_mode" class="cmd_tips">可用语音指令：“帮我切换x号链接”、“帮我对比x和y号链接”</div>
    <div v-if="tv_mode" class="cmd_progress">
      <div class="progress_bar" :style="{ width: cmdProgress + '%' }"></div>
      <div class="progress_txt">{{ formattedRemainingTime }}</div>
    </div>
    <div class="info-box" v-if="show_card_left || show_card_right">
      <div class="model-box-left">
        <div v-show="show_card_left" class="title-box title-box-left">
          <div class="model-info">
            <span v-if="order_num_left > -1" class="badge">{{ order_num_left }}#</span>
            <span class="title title-left">{{ modelName_left }}</span
            >
            <!-- <img :src="img_left" width="100px" alt="car" /> -->
          </div>
          <div class="title-bg-left"></div>
        </div>
      </div>
      <div class="model-box-right">
        <img class="vs" src="/images/vs.png" v-if="vs_mode" />
        <div v-show="show_card_right" class="title-box">
          <div class="model-info">
            <!-- <img :src="img_right" width="100px" alt="car" /> -->
            <span class="title">{{ modelName_right }}</span>
            <span v-if="order_num_right > -1" class="badge">{{ order_num_right }}#</span>
          </div>
          <div class="title-bg-right"></div>
        </div>
      </div>
    </div>
    <div :class="couponInfoClass" v-if="couponName && tv_mode">当前优惠券：{{ couponName }} ({{ coupon_count_down }}s)</div>
    <div class="param-box" v-if="show_card_left || show_card_right">
      <div class="param-box-left">
        <div class="dataItem">
          <span v-if="original_price_left - discounted_price_left > 1" class="coupon">直降￥{{ original_price_left - discounted_price_left }}</span> <span v-if="coupon_left" class="coupon">￥{{ coupon_left }}元券</span>
          <span v-if="original_price_left - discounted_price_left <= 1 || coupon_left <= 0">&nbsp;</span>
        </div>
        <div class="dataItem">
          <span v-if="original_price_left" class="original">￥{{ original_price_left }}</span
          ><span v-if="discounted_price_left" class="discounted">￥{{ discounted_price_left - coupon_left }}</span>
          <span v-if="!original_price_left" class="original">&nbsp;</span>
        </div>
        <div class="dataItem">{{ type_left }}</div>
        <div class="dataItem">{{ speed_left }}</div>
        <div class="dataItem">{{ endurance_left }}</div>
        <div class="dataItem">{{ battery_left }}</div>
        <div class="dataItem">{{ motor_power_left }}</div>
        <div class="dataItem">{{ color_left }}</div>
        <div class="dataItem" v-if="tv_mode" style="color: red">{{ exclusive_links_left }}</div>
        <div class="dataItem" v-else>
          <span class="coupon" v-if="exclusive_links_left && exclusive_links_left != '无'">告诉主播您的城市，可能会有地区链接，免服务费哦！</span>
          <span v-else></span>
        </div>
        <div class="dataItem">{{ more_info_left }}</div>
      </div>
      <div class="param-box-right">
        <div class="dataItem">
          <div class="dataKey">优惠</div>
          <span v-if="original_price_right - discounted_price_right > 1" class="coupon">直降￥{{ original_price_right - discounted_price_right }}</span> <span v-if="coupon_right" class="coupon">￥{{ coupon_right }}元券</span>
          <span v-if="original_price_right - discounted_price_right <= 1 || coupon_right <= 0">&nbsp;</span>
        </div>
        <div class="dataItem">
          <div class="dataKey">现价</div>
          <span v-if="discounted_price_right" class="discounted">￥{{ discounted_price_right - coupon_right }}</span
          ><span v-if="original_price_right" class="original">￥{{ original_price_right }}</span>
          <span v-if="!original_price_right" class="original">&nbsp;</span>
        </div>
        <div class="dataItem">
          <div class="dataKey">类型</div>
          {{ type_right }}
        </div>
        <div class="dataItem">
          <div class="dataKey">极速</div>
          {{ speed_right }}
        </div>
        <div class="dataItem">
          <div class="dataKey">续航</div>
          {{ endurance_right }}
        </div>
        <div class="dataItem">
          <div class="dataKey">电池</div>
          {{ battery_right }}
        </div>
        <div class="dataItem">
          <div class="dataKey">功率</div>
          {{ motor_power_right }}
        </div>
        <div class="dataItem">
          <div class="dataKey">颜色</div>
          {{ color_right }}
        </div>
        <div class="dataItem" v-if="tv_mode">
          <div class="dataKey">地链</div>
          <span style="color: red">{{ exclusive_links_right }}</span>
        </div>
        <div class="dataItem" v-else>
          <div class="dataKey">地链</div>
          <span class="coupon" v-if="exclusive_links_right && exclusive_links_right != '无'">告诉主播您的城市，可能会有地区链接，免服务费哦！</span>
          <span v-else></span>
        </div>
        <div class="dataItem">
          <div class="dataKey">其它</div>
          <div>{{ more_info_right }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Centrifuge, UnauthorizedError } from "centrifuge";
import QrCodeComponent from "./components/qr-code-component.vue";
export default {
  name: "App",
  components: {
    QrCodeComponent,
  },
  data() {
    return {
      mode_class: "",
      tv_mode: false,
      vs_mode: false,
      dataList: [],
      show_card_left: false,
      show_card_right: false,
      modelName_left: "",
      modelName_right: "",
      order_num_left: -1,
      order_num_right: -1,
      img_left: "#",
      img_right: "#",
      type_left: "",
      type_right: "",
      speed_left: "",
      speed_right: "",
      endurance_left: "",
      endurance_right: "",
      battery_left: "",
      battery_right: "",
      motor_power_left: "",
      motor_power_right: "",
      color_left: "",
      color_right: "",
      more_info_left: "",
      more_info_right: "",
      original_price_left: 0,
      original_price_right: 0,
      discounted_price_left: 0,
      discounted_price_right: 0,
      coupon_left: 0,
      coupon_right: 0,
      consoleUrl: "",
      exclusive_links_left: "",
      exclusive_links_right: "",
      message: "使用微信扫码，可自行切换讲解车型和发券哦！",
      lastCouponName: "",
      couponName: "",
      coupon_count_down: "",
      coupon_count_down_timer: undefined,
      coupon_count_down_show_index: 0,
      couponInfoClass: "couponInfo green",
      currentAudioUrl: "",
      welcomeDlgVisible: true,
      cmdProgress: 0,
      formattedRemainingTime: "00:00",
      auduoTimer: undefined,
    };
  },
  methods: {
    async getRoomToken() {
      const formData = new FormData();
      formData.set("room", this.roomCode);
      const res = await fetch("//ss.afqmf.com/getRoomToken", {
        method: "POST",
        body: formData,
      });
      if (!res.ok) {
        if (res.status === 403) {
          // Return special error to not proceed with token refreshes, subscription will be unsubscribed.
          throw new UnauthorizedError();
        }
        // Any other error thrown will result into token refresh re-attempts.
        throw new Error(`Unexpected status code ${res.status}`);
      }
      const data = await res.json();
      return data.token;
    },
    async getConnectToken() {
      const res = await fetch("//ss.afqmf.com/getConnectToken");
      if (!res.ok) {
        if (res.status === 403) {
          // Return special error to not proceed with token refreshes, subscription will be unsubscribed.
          throw new UnauthorizedError();
        }
        // Any other error thrown will result into token refresh re-attempts.
        throw new Error(`Unexpected status code ${res.status}`);
      }
      const data = await res.json();
      return data.token;
    },
    reload(roomCode) {
      const that = this;
      // 这里不能用reload接口，否则会一直循环刷新

      const formData = new FormData();
      formData.set("room", roomCode);
      fetch("//ss.afqmf.com/list", {
        method: "POST",
        body: formData,
      }).then((res) => {
        res.json().then((jdata) => {
          that.dataList = jdata.data;
        });
      });
    },
    getModelData(mode, key) {
      if (mode == "showModel") {
        let index = this.dataList.findIndex((item) => item.model == key);
        let data = this.dataList[index];
        if (data) {
          this.vs_mode = false;
          if (this.tv_mode) {
            // 电视模式
            this.modelName_right = "";
            this.img_right = "#";
            this.speed_right = "";
            this.type_right = "";
            this.endurance_right = "";
            this.battery_right = "";
            this.motor_power_right = "";
            this.color_right = "";
            this.more_info_right = "";
            this.original_price_right = 0;
            this.discounted_price_right = 0;
            this.coupon_right = 0;
            this.show_card_right = false;
            this.order_num_right = -1;
            this.exclusive_links_right = "";

            this.modelName_left = key.substring(0, 13) + (key.length > 15 ? "..." : "");
            this.order_num_left = data.order_num;
            this.img_left = data.img;
            this.type_left = data.type;
            this.speed_left = data.speed;
            this.endurance_left = data.endurance;
            this.battery_left = data.battery;
            this.motor_power_left = data.motor_power;
            this.color_left = data.color;
            this.more_info_left = data.more_info;
            this.original_price_left = data.original_price;
            this.discounted_price_left = data.discounted_price;
            this.coupon_left = data.coupon;
            this.show_card_left = true;
            this.exclusive_links_left = data.exclusive_links;
          } else {
            // 直播模式
            this.modelName_left = "";
            this.img_left = "#";
            this.type_left = "";
            this.speed_left = "";
            this.endurance_left = "";
            this.battery_left = "";
            this.motor_power_left = "";
            this.color_left = "";
            this.more_info_left = "";
            this.original_price_left = 0;
            this.discounted_price_left = 0;
            this.coupon_left = 0;
            this.show_card_left = false;
            this.order_num_left = -1;
            this.exclusive_links_left = "";

            this.modelName_right = key.substring(0, 13) + (key.length > 15 ? "..." : "");
            this.order_num_right = data.order_num;
            this.img_right = data.img;
            this.type_right = data.type;
            this.speed_right = data.speed;
            this.endurance_right = data.endurance;
            this.battery_right = data.battery;
            this.motor_power_right = data.motor_power;
            this.color_right = data.color;
            this.more_info_right = data.sort_info;
            this.original_price_right = data.original_price;
            this.discounted_price_right = data.discounted_price;
            this.coupon_right = data.coupon;
            this.show_card_right = true;
            this.exclusive_links_right = data.exclusive_links;
          }
        }
      } else if (mode == "contrast") {
        let key_array = key.split(",");
        if (key_array && key_array.length > 1) {
          let left_key = key_array[0];
          let left_index = this.dataList.findIndex((item) => item.model == left_key);
          let left_data = this.dataList[left_index];
          if (left_data) {
            this.modelName_left = left_key.substring(0, 13) + (left_key.length > 15 ? "..." : "");
            this.order_num_left = left_data.order_num;
            this.type_left = left_data.type;
            this.speed_left = left_data.speed;
            this.endurance_left = left_data.endurance;
            this.battery_left = left_data.battery;
            this.motor_power_left = left_data.motor_power;
            this.color_left = left_data.color;
            this.more_info_left = this.tv_mode ? left_data.more_info : left_data.sort_info;
            this.img_left = left_data.img;
            this.original_price_left = left_data.original_price;
            this.discounted_price_left = left_data.discounted_price;
            this.coupon_left = left_data.coupon;
            this.show_card_left = true;
            this.exclusive_links_left = left_data.exclusive_links;
          }

          let right_key = key_array[1];
          let right_index = this.dataList.findIndex((item) => item.model == right_key);
          let right_data = this.dataList[right_index];
          if (right_data) {
            this.modelName_right = right_key.substring(0, 13) + (right_key.length > 15 ? "..." : "");
            this.order_num_right = right_data.order_num;
            this.type_right = right_data.type;
            this.speed_right = right_data.speed;
            this.endurance_right = right_data.endurance;
            this.battery_right = right_data.battery;
            this.motor_power_right = right_data.motor_power;
            this.color_right = right_data.color;
            this.more_info_right = this.tv_mode ? right_data.more_info : right_data.sort_info;
            this.img_right = right_data.img;
            this.exclusive_links_right = right_data.exclusive_links;
            (this.original_price_right = right_data.original_price), (this.discounted_price_right = right_data.discounted_price), (this.coupon_right = right_data.coupon), (this.show_card_right = true);
          }

          this.vs_mode = true;
        }
      }
    },
    setCountDown(issue_end_time) {
      if (issue_end_time) {
        var date1 = new Date();
        var date2 = new Date(issue_end_time * 1000);
        var difference = date2 - date1;
        console.log(date2, date1);
        // 将差值转换为秒
        var secondsDifference = Math.ceil(difference / 1000);
        if (secondsDifference > 0) {
          this.coupon_count_down = secondsDifference;
          if (this.coupon_count_down_timer) {
            clearInterval(this.coupon_count_down_timer);
          }
          this.coupon_count_down_timer = setInterval(() => {
            if (this.coupon_count_down <= 0) {
              // clearInterval(this.coupon_count_down_timer);
              // this.coupon_count_down_timer = undefined;
              this.couponName = "[" + this.lastCouponName + "]发完了，快发新券吧！";
              this.coupon_count_down_show_index++;
              if (this.coupon_count_down_show_index % 2) {
                this.couponInfoClass = "couponInfo green";
              } else {
                this.couponInfoClass = "couponInfo red";
              }
            } else {
              this.coupon_count_down_show_index = 0;
              this.couponInfoClass = "couponInfo green";
              this.coupon_count_down--;
            }
          }, 1000);
        }
      } else {
        clearInterval(this.coupon_count_down_timer);
        this.coupon_count_down = 0;
      }
    },
    updateRemainingTime() {
      const audioPlayer = this.$refs.audioPlayer;
      if (audioPlayer) {
        if (audioPlayer.duration) {
          // 计算剩余时间
          var remainingTime = audioPlayer.duration - audioPlayer.currentTime;
          console.log(audioPlayer.duration, audioPlayer.currentTime);
          this.cmdProgress = Math.floor((audioPlayer.currentTime / audioPlayer.duration) * 100);
          // 将时间转换为MM:SS格式
          var minutes = Math.floor(remainingTime / 60);
          var seconds = Math.floor(remainingTime % 60);
          this.formattedRemainingTime = minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
        }
        // 如果音频仍在播放，则在1秒后再次调用此函数
        if (!audioPlayer.paused) {
          this.auduoTimer = setTimeout(this.updateRemainingTime, 200);
        }
      }
    },
    playAudio(audioUrl) {
      // console.log(audioUrl);
      this.currentAudioUrl = audioUrl;
      this.$nextTick(() => {
        const audioPlayer = this.$refs.audioPlayer;
        if (audioPlayer) {
          audioPlayer.play();
          audioPlayer.addEventListener("play", this.updateRemainingTime);
          audioPlayer.addEventListener("ended", () => {
            clearTimeout(this.auduoTimer);
            this.cmdProgress = 100;
            setTimeout(() => {
              this.cmdProgress = 0;
            }, 1000);
          });
        }
      });
    },
  },
  async mounted() {
    const that = this;
    const params = new URLSearchParams(window.location.search);
    this.roomCode = params.get("room");
    if (this.roomCode) {
      this.consoleUrl = "http://sc.afqmf.com/?room=" + this.roomCode;
    }
    this.tv_mode = params.get("mode") === "tv";
    this.mode_class = this.tv_mode ? "tv-mode" : "";

    console.log(this.roomCode);
    let Connect_token = await this.getConnectToken();
    let room_token = await this.getRoomToken();
    // console.log(Connect_token, room_token);

    this.reload(this.roomCode);

    let centrifuge_client = new Centrifuge("wss://wxapp.afqmf.com:3221/connection/websocket", {
      token: Connect_token,
      getToken: this.getConnectToken,
    });
    centrifuge_client
      .on("connecting", function (ctx) {
        console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
      })
      .on("connected", function (ctx) {
        console.log(`connected over ${ctx.transport}`);
      })
      .on("disconnected", function (ctx) {
        console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
      })
      .connect();

    const room_sub = centrifuge_client.newSubscription(this.roomCode, {
      token: room_token,
      getToken: this.getRoomToken,
    });

    room_sub
      .on("publication", function (ctx) {
        console.log("Received data:", ctx.data);
        const cmd = ctx.data.cmd;
        const room = ctx.data.room;
        if (cmd.toUpperCase() == "reload".toUpperCase()) {
          that.reload(room);
        } else if (cmd.toUpperCase() == "clean".toUpperCase()) {
          that.show_card_left = false;
          that.show_card_right = false;
        } else if (cmd.toUpperCase() == "contrast".toUpperCase()) {
          that.getModelData("contrast", ctx.data.model);
        } else if (cmd.toUpperCase() == "showModel".toUpperCase()) {
          that.show_card_right = false;
          that.getModelData("showModel", ctx.data.model);
        } else if (cmd.toUpperCase() == "message".toUpperCase()) {
          if (ctx.data.message) {
            that.message = "中控消息：" + ctx.data.message;
          } else {
            that.message = "主播扫码可自行切换讲解车型！";
          }
        } else if (cmd.toUpperCase() == "send_coupon".toUpperCase()) {
          that.lastCouponName = ctx.data.coupon_name;
          that.couponName = ctx.data.coupon_name;
          that.setCountDown(ctx.data.expires);
        } else if (cmd.toUpperCase() == "play_audio".toUpperCase()) {
          if (that.tv_mode) {
            var audioUrl = ctx.data.audioUrl;
            if (audioUrl.indexOf("//") == -1) {
              that.playAudio("/products/" + audioUrl);
            } else {
              that.playAudio(audioUrl);
            }
          }
        }
      })
      .on("subscribing", function (ctx) {
        console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
      })
      .on("subscribed", function (ctx) {
        console.log("subscribed", ctx);
      })
      .on("unsubscribed", function (ctx) {
        console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
      })
      .subscribe();
  },
};
</script>

<style type="text/css">
.tv-mode {
  background-color: #000000 !important;
  color: #ffffff !important;
  height: 100vh;
}
.tv-mode .title-box {
  font: bold 3rem "Open Sans", sans-serif !important;
}

.tv-mode .info-box {
  background-color: #636363;
  padding-bottom: 10px;
}

.tv-mode .dataKey {
  text-shadow: none !important;
  color: yellow !important;
  font: bold 1.5rem / 1 "Open Sans", sans-serif !important;
  line-height: 35px !important;
  height: 35px !important;
}
.tv-mode .dataItem {
  text-shadow: none !important;
  font: bold 1.5rem / 1 "Open Sans", sans-serif !important;
  line-height: 35px !important;
}
.tv-mode .discounted,
.tv-mode .coupon {
  text-shadow: none !important;
}

.tv-mode .badge {
  font-size: 2rem !important;
}

.original {
  color: #ffffff !important;
  text-decoration: line-through;
  font-size: 1.5rem;
  margin-left: 10px;
}
.discounted {
  color: rgb(255, 0, 0) !important;
  text-shadow: 0px 0px 5px rgb(255, 255, 255) !important;
}
.coupon {
  color: rgb(255, 0, 0) !important;
  text-shadow: 0px 0px 5px rgb(255, 255, 255) !important;
}
.info-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.vs {
  height: 50px;
  margin: 50px 0 0 0;
  padding: 0 35px;
}
.model-box-left {
  width: 45%;
  display: flex;
}
.model-box-right {
  width: 55%;
  display: flex;
}
.title-box {
  font: bold 2rem "Open Sans", sans-serif;
  color: #2b2b2b;
  padding-left: 30px;
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.title-box-left {
  justify-content: flex-end !important;
}
.model-info {
  z-index: 1;
  display: flex;
  align-items: flex-end;
}
.title-bg-right {
  background-color: #f0d86d;
  width: 90%;
  height: 30px;
  position: absolute;
  left: 0;
  transform: skew(60deg);
  z-index: 0;
  border-left: 10px #000 solid;
}
.title-bg-left {
  background-color: #f0d86d;
  width: 90%;
  height: 30px;
  position: absolute;
  right: 0;
  transform: skew(120deg);
  z-index: 0;
  border-right: 10px #000 solid;
}

.title {
  /* background: #eee url(data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAAHklEQVQImWNkYGBgYGD4//8/A5wF5SBYyAr+//8PAPOCFO0Q2zq7AAAAAElFTkSuQmCC) repeat; */
  /* -webkit-text-fill-color: transparent;
  -webkit-background-clip: text; */
  text-shadow: -1px 1px white;
  padding: 5px 0px;
}
.title-left {
  text-align: right;
}
.param-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 10px 0;
  margin: 10px;
}
.param-box-left {
  width: 45%;
  text-align: right;
}

.param-box-right {
  width: 55%;
  text-align: left;
}

.dataKey {
  text-wrap: nowrap;
  width: 100px;
  text-align: center;
  flex-shrink: 0;
}

.dataItem,
.dataKey {
  font: bold 1.5rem "Open Sans", sans-serif;
  line-height: 35px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  text-shadow: 0px 0px 5px rgb(0, 0, 0);
}

.more_info {
  font: bold 1.5rem "Open Sans", sans-serif;
  height: 100%;
  margin-top: 10px;
}

.param-box-right .dataItem {
  display: flex;
}
.badge {
  color: #ff0000;
  font-size: 1rem;
  padding: 0 10px;
}
.couponInfo {
  font: bold 1.5rem "Open Sans", sans-serif;
  width: 100%;
  padding-top: 10px;
  color: #2fff00;
  text-align: center;
  min-height: 30px;
}

.red {
  color: #ff0000 !important;
}

.green {
  color: #2fff00 !important;
}
.welcomeDlg {
  width: 500px;
  height: 300px;
  background-color: #888;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

.welcomeDlg .content {
  padding: 10px;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}

.welcomeDlg .btn {
  width: 100%;
  height: 50px;
  background-color: #ff0000;
  color: #ffffff;
  font-size: 1.5rem;
  border-radius: 10px;
  margin-top: 10px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}

.welcomeDlg .btn:hover {
  background-color: #1d7511;
  color: #ffffff;
}

.cmd_tips {
  color: #ffffff;
  background-color: #636363;
  font-size: 1.2rem;
  padding: 5px;
}
.cmd_progress {
  background-color: #9d9d9d;
  height: 15px;
  width: 100%;
  position: relative;
}
.progress_bar {
  background-color: #ff0000;
  height: 15px;
  position: absolute;
  left: 0;
  top: 0;
}
.progress_txt {
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  line-height: 15px;
}
</style>
