<template>
  <div style="display: flex; align-items: center; background-color: #fff; color: #000">
    <canvas style="position: absolute;left: 0;top: 320px;z-index: 999;" ref="qrcodeCanvas"></canvas>
    <div style="font-size: 2rem; text-align: center;margin: 5px 0;color:red;">{{message}}</div>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  name: "QrCodeComponent",
  props: {
    text: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
  },
  mounted() {
    this.generateQRCode();
  },
  methods: {
    generateQRCode() {
      const canvas = this.$refs.qrcodeCanvas;
      QRCode.toCanvas(canvas, this.text, { width: 120 }, (error) => {
        if (error) console.error(error);
      });
    },
  },
};
</script>
